<template>
  <div>
    <v-form @submit.prevent="getCtes()">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="params.from"
            label="Data Inicial"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="params.to"
            label="Data Final"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn :loading="loading" type="submit" color="success">Buscar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-data-table
      :items="ctes"
      :headers="headers"
      disable-pagination
      hide-default-footer
      dense
      :loading="loading"
    >
      <template v-slot:[`item.number`]="{ item }">
        <a :href="`/#/editar-cte/${item.id}`" target="_blank">{{
          item.number
        }}</a>
      </template>
      <template v-slot:[`item.tde`]="{ item }">
        <span>{{ $functions.moneyFormat(item.tde) }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      params: {
        from: "",
        to: "",
      },
      ctes: [],
      headers: [
        { text: "CTE", value: "number" },
        { text: "Destinatário", value: "recipients.name" },
        { text: "Cidade", value: "recipients.cities.name" },
        { text: "TDE", value: "tde" },
      ],
    };
  },
  methods: {
    getCtes() {
      this.loading = true;
      this.$http
        .get(
          `api/ctes-without-tde?from=${this.params.from}&to=${this.params.to}`
        )
        .then(({ data }) => {
          this.ctes = data;
          this.loading = false;
        })
        .catch((err) => {
        	this.loading = false;
          this.$side({
            type: "error",
            msg: "Erro ao buscar CTEs",
            duration: 4000,
          });
          console.log(err);
        });
    },
  },
};
</script>

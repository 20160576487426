<template>
	<div>
		<h1>Relatórios</h1>
		<v-expansion-panels>
	    <v-expansion-panel>
	      <v-expansion-panel-header>
	        CTEs sem TDA
 	      </v-expansion-panel-header>
	      <v-expansion-panel-content>
	        <ctes-without-fees />
	      </v-expansion-panel-content>
	    </v-expansion-panel>
	    <v-expansion-panel>
	      <v-expansion-panel-header>
	        CTEs sem TDE
 	      </v-expansion-panel-header>
	      <v-expansion-panel-content>
	        <ctes-without-tde />
	      </v-expansion-panel-content>
	    </v-expansion-panel>
	  </v-expansion-panels>
	</div>
</template>

<script>

import CtesWithoutFees from './CtesWithoutFees.vue';
import CtesWithoutTde from './CtesWithoutTde.vue';

	export default {
		components: {
			CtesWithoutFees,
			CtesWithoutTde,
		}
	}
</script>